<template scoped>
  <div :style="{ height: '100%' }">
    <el-page-header
      :style="{ margin: '10px' }"
      @back="back"
      content="Design Tool"
    >
    </el-page-header>
    <el-form
      :style="{
        padding: '10px',
        margin: '10px',
        height: 'calc(100% - 80px)',
        overflowY: 'auto',
        background: '#ffffff',
      }"
      status-icon
      ref="detail"
      class="logic"
      :model="logic"
      label-width="180px"
    >
      <el-form-item
        label="Action"
        prop="action"
        :rules="rules.action"
        :style="{ marginTop: '10px' }"
      >
        <el-select
          @change="action_change"
          v-model="logic.action"
          :style="{ width: '100%' }"
          placeholder="Select the Action"
        >
          <el-option
            v-for="item in supportAction"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <component
        :is="content"
        :endpoint="endpoint"
        :logic="logic"
        :productId="parameter.productId"
      ></component>

      <el-form-item
        :rules="rules.conditionEnable"
        label="Condition"
        prop="condition.enable"
        :style="{ marginTop: '10px' }"
      >
        <el-switch
          v-model="logic.condition.enable"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>

        <el-form-item
          v-if="logic.condition.enable"
          labelWidth="70px"
          :rules="rules.variable"
          label="Variable"
          prop="condition.variable"
          :style="{ marginTop: '10px' }"
        >
          <el-input
            :disabled="!logic.condition.enable"
            v-model="logic.condition.variable"
            placeholder=""
          >
            <el-switch
              slot="append"
              v-model="logic.condition.variableValueType"
              active-text="Value"
              active-value="Value"
              inactive-text="Variable"
              inactive-value="Variable"
            ></el-switch>
          </el-input>
        </el-form-item>

        <el-form-item
          v-if="logic.condition.enable"
          labelWidth="70px"
          :rules="rules.operator"
          label="Operator"
          prop="condition.operator"
          :style="{ marginTop: '10px' }"
        >
          <el-select
            :disabled="!logic.condition.enable"
            v-model="logic.condition.operator"
            :style="{ width: '100%' }"
            placeholder="Select the Operator"
          >
            <el-option
              v-for="item in supportConditionOperator"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="logic.condition.enable"
          labelWidth="70px"
          :rules="rules.value"
          label="Value"
          prop="condition.value"
          :style="{ marginTop: '10px' }"
        >
          <el-input
            :disabled="!logic.condition.enable"
            v-model="logic.condition.value"
            placeholder=""
          >
            <el-switch
              slot="append"
              v-model="logic.condition.valueVariableType"
              active-text="Value"
              active-value="Value"
              inactive-text="Variable"
              inactive-value="Variable"
            ></el-switch
          ></el-input>
        </el-form-item>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="create" v-if="!parameter.id"
          >Create</el-button
        >
        <el-button type="primary" @click="update" v-if="parameter.id"
          >Update</el-button
        >
        <el-button @click="back">Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    toPage: Function,
    parameter: Object,
    endpoint: String,
  },
  methods: {
    action_change: function (val) {
      switch (val) {
        case "Connect":
          this.content = () => import("./Connect/Panel");
          break;
        case "Send":
          this.content = () => import("./Send/Panel");
          break;
        case "Execute":
          this.content = () => import("./Execute/Panel");
          break;
        case "Read":
          this.content = () => import("./Read/Panel");
          break;
        case "Save":
          this.content = () => import("./Save/Panel");
          break;
        case "Operator":
          this.content = () => import("./Operator/Panel");
          break;
        default:
          this.content = null;
          break;
      }
    },
    back: function () {
      this.toPage("components/FlowLogic/Portal-FlowLogic.vue", {
        flowId: this.parameter.flowId,
        productId: this.parameter.productId,
      });
    },
    update: function () {
      var $this = this;
      this.$refs.detail.validate((valid) => {
        if (valid) {
          $this.$plugin.request
            .put(
              `${$this.endpoint}/api/Backend/FlowLogic/${this.parameter.id}`,
              { body: $this.logic }
            )
            .then(function () {
              $this.back();
            });
        }
      });
    },
    create: function () {
      var $this = this;
      this.$refs.detail.validate((valid) => {
        if (valid) {
          $this.$plugin.request
            .post(`${$this.endpoint}/api/Backend/FlowLogic`, {
              body: $this.logic,
            })
            .then(function () {
              $this.back();
            });
        }
      });
    },
    validator_action: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the action should not be empty"));
      } else {
        callback();
      }
    },
    validator_conditionEnable: function (rule, value, callback) {
      callback();
    },
    validator_variable: function (rule, value, callback) {
      if (this.logic.condition.enable && !value) {
        callback(new Error("the variable should not be empty"));
      } else {
        callback();
      }
    },
    validator_operator: function (rule, value, callback) {
      if (this.logic.condition.enable && !value) {
        callback(new Error("the operator should not be empty"));
      } else {
        callback();
      }
    },
    validator_value: function (rule, value, callback) {
      if (this.logic.condition.enable && !value) {
        callback(new Error("the value should not be empty"));
      } else {
        callback();
      }
    },
  },
  data: function () {
    return {
      logic: {
        flowId: this.parameter.flowId,
        action: null,
        connect: {
          type: null,
          bluetooth: {
            interface: null,
            ble: {
              service: null,
              serviceValueType: "Value",
              descriptorMethod: null,
              descriptor: null,
              descriptorValueType: "Value",
              characteristic: null,
              characteristicValueType: "Value",
            },
            classic: {
              rfcomm: null,
              rfcommValueType: "Value",
            },
          },
        },
        send: {
          command: null,
          commandValueType: "Value",
          checksum: {
            enable: false,
            type: null,
          },
        },
        read: {
          type: null,
          match: {
            regex: null,
            regexValueType: "Value",
          },
          untilMatch: {
            regex: null,
            regexValueType: "Value",
          },
        },
        execute: {
          type: null,
          flow: {
            id: null,
          },
          sleep: {
            second: null,
            secondValueType: "Value",
          },
          flowLogic: {
            id: null,
          },
          end: {},
        },
        operator: {
          string: {
            inputVariable: null,
            inputVariableValueType: "Variable",
            outputVariable: null,
            outputVariableValueType: "Variable",
            logic: [],
          },
          mathematic: {
            inputVariable: null,
            inputVariableValueType: "Variable",
            outputVariable: null,
            outputVariableValueType: "Variable",
            logic: [],
          },
          checksum: {
            type: null,
            value: [],
          },
        },
        save: {
          type: null,
          bloodPressure: {
            systolic: null,
            systolicValueType: "Value",
            diastolic: null,
            diastolicValueType: "Value",
            pulse: null,
            pulseValueType: "Value",
            year: null,
            yearValueType: "Value",
            month: null,
            monthValueType: "Value",
            day: null,
            dayValueType: "Value",
            hour: null,
            hourValueType: "Value",
            minute: null,
            minuteValueType: "Value",
            second: null,
            secondValueType: "Value",
          },
          heartRate: {
            pulse: null,
            pulseValueType: "Value",
            year: null,
            yearValueType: "Value",
            month: null,
            monthValueType: "Value",
            day: null,
            dayValueType: "Value",
            hour: null,
            hourValueType: "Value",
            minute: null,
            minuteValueType: "Value",
            second: null,
            secondValueType: "Value",
          },
          temperature: {
            degreeCelsius: null,
            degreeCelsiusValueType: "Value",
            year: null,
            yearValueType: "Value",
            month: null,
            monthValueType: "Value",
            day: null,
            dayValueType: "Value",
            hour: null,
            hourValueType: "Value",
            minute: null,
            minuteValueType: "Value",
            second: null,
            secondValueType: "Value",
          },
        },
        condition: {
          enable: false,
          variable: null,
          variableValueType: "Variable",
          operator: null,
          value: null,
          valueVariableType: "Value",
        },
      },
      content: null,
      supportAction: [
        {
          name: "Connect",
          value: "Connect",
        },
        {
          name: "Send",
          value: "Send",
        },
        {
          name: "Read",
          value: "Read",
        },
        {
          name: "Execute",
          value: "Execute",
        },
        {
          name: "Operator",
          value: "Operator",
        },
        {
          name: "Save",
          value: "Save",
        },
      ],
      supportConditionOperator: [
        {
          name: "Equal",
          value: "Equal",
        },
        {
          name: "Not Equal",
          value: "NotEqual",
        },
      ],
      success: false,
      rules: {
        action: [{ validator: this.validator_action, trigger: "blur" }],
        conditionEnable: [
          { validator: this.validator_conditionEnable, trigger: "blur" },
        ],
        variable: [{ validator: this.validator_variable, trigger: "blur" }],
        operator: [{ validator: this.validator_operator, trigger: "blur" }],
        value: [{ validator: this.validator_value, trigger: "blur" }],
      },
    };
  },
  created: function () {
    if (this.parameter.id) {
      var $this = this;
      this.$plugin.request
        .get(`${this.endpoint}/api/Backend/FlowLogic/${this.parameter.id}`)
        .then(function (result) {
          $this.logic = result;
          $this.action_change(result.action);
        });
    }
  },
};
</script>

<style scoped>
</style>