import { render, staticRenderFns } from "./Portal-FlowLogic-CreateUpdate.vue?vue&type=template&id=65f690f3&scoped=true&scoped=true&"
import script from "./Portal-FlowLogic-CreateUpdate.vue?vue&type=script&lang=js&"
export * from "./Portal-FlowLogic-CreateUpdate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65f690f3",
  null
  
)

export default component.exports